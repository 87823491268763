<template>
	<b-card class="scrollme">
		<b-button
			v-b-toggle.create-attribute-collapse
			pill
			variant="outline-primary"
			size="sm"
			class="mb-1 ml-auto d-block"
		>
			Add new attribute
		</b-button>
		<b-collapse
			id="create-attribute-collapse"
			class="border mb-1 rounded shadow-sm p-1"
		>
			<AddAttributes :refresh-table="getAllAttributes" />
		</b-collapse>

		<b-table
			hover
			:items="attributesList"
			:fields="fields"
			bordered
			responsive
			class="shadow-sm rounded"
			thead-tr-class="order-main-thead"
			primary-key="id"
			show-empty
			empty-text="No matching records found"
			small
		/>
		<template #cell(subCategoryId)="data">
			<!-- {{ subCategoryById(data.item.subCategoryId) }} -->
			{{ data.item.nameEng }}
		</template>
	</b-card>
</template>

<script>
import { BTable, BButton, BCard, BCollapse, VBToggle } from 'bootstrap-vue';
import { mapActions, mapState } from 'vuex';
import AddAttributes from './AddAttributes.vue';

export default {
	components: {
		BTable,
		BButton,
		BCard,
		AddAttributes,
		BCollapse,
	},
	directives: {
		'b-toggle': VBToggle,
	},
	data() {
		return {
			popupIsOpen: false,
			createPopUpIsOpen: false,
			categoriesList: null,
			attributesList: null,
			fields: [
				{
					key: 'id',
					label: 'ID',
				},
				{
					key: 'nameGeo',
					label: 'Name',
					formatter: (value, key, item) => `${item.nameGeo} / ${item.nameEng}`,
				},
				{
					key: 'subCategoryId',
					label: 'Sub Category',
					formatter: (value) => this.subCategoryById(value),
				},
				{
					key: 'isConfigurable',
					label: 'Configurable',
					formatter: (value, key, item) => (item.isConfigurable ? 'Yes' : 'No'),
				},
			],
		};
	},
	computed: {
		...mapState('parametersModule', [
			'categories',
			'allAttributes',
			'subCategories',
		]),
	},
	watch: {
		// subCategories(newVal) {
		// 	console.log('subcategories', newVal);
		// },
		categories(newVal) {
			this.categoriesList = newVal.data.productCategories;
			console.log('categories ', this.categoriesList);
		},
		allAttributes(newVal) {
			this.attributesList = newVal.productAttributes;
			console.log('attributes ', this.attributesList);
		},
	},
	created() {
		this.getSubCategories().then(() => {
			console.log('subcategories', this.subCategories);
		});
		this.getCategories();
		this.getAllAttributes();
	},
	methods: {
		...mapActions('parametersModule', [
			'getSubCategories',
			'getCategories',
			'getAllAttributes',
		]),
		subCategoryById(id) {
			console.log('hit');
			const subCat = this.subCategories.data.productSubCategories.find(
				(x) => x.productSubCategoryId === id
			);

			return subCat
				? `${subCat.productSubCategoryNameGeo} / ${subCat.productSubCategoryNameEng}`
				: '';
		},
		onClickAttributeRow(e) {
			this.popupIsOpen = !this.popupIsOpen;
			this.getCategory({ id: e.productCategoryId });
		},
		onRowClicked(e) {
			console.log(e);
		},
		showCreatePopUp() {
			this.createPopUpIsOpen = !this.createPopUpIsOpen;
		},
	},
};
</script>

<style lang="scss" scoped>
.scrollme {
	overflow-x: auto;
}
</style>
