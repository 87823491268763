<template>
	<div class="d-flex">
		<b-col v-if="subCategories" md="4">
			<b-form-group v-slot="{ ariaDescribedby }" label="Product Sub Categories">
				<b-form-radio
					v-for="item in subCategories.data.productSubCategories"
					:key="item.id"
					v-model="selectedCategory.id"
					:aria-describedby="ariaDescribedby"
					name="some-radios"
					class="mb-1"
					:value="item.productSubCategoryId"
					>{{ item.productSubCategoryNameEng }} /
					{{ item.productSubCategoryNameGeo }}
				</b-form-radio>
			</b-form-group>
		</b-col>
		<b-col md="8">
			<div class="save-wrapper">
				<div class="d-flex">
					<b-form-group
						label="Geo name"
						label-for="attribute-name"
						class="mr-1 w-100"
					>
						<b-form-input
							id="attribute-name"
							v-model="productAttribute.nameGeo"
							type="text"
							:disabled="selectedCategory.id === null"
							placeholder="Enter attribute"
						/>
					</b-form-group>
					<b-form-group
						label="Eng name"
						label-for="attribute-name"
						class="w-100"
					>
						<b-form-input
							id="attribute-name"
							v-model="productAttribute.nameEng"
							:disabled="selectedCategory.id === null"
							type="text"
							placeholder="Enter attribute"
						/>
					</b-form-group>
				</div>
				<div class="d-flex align-items-center justify-content-between">
					<b-form-checkbox
						v-model="productAttribute.isConfigurable"
						:value="true"
						:disabled="selectedCategory.id === null"
					>
						Is configurable
					</b-form-checkbox>
					<b-button
						variant="primary"
						pill
						:disabled="isBtnDisabled"
						@click="save()"
					>
						<span>Save</span>
					</b-button>
				</div>
			</div>
		</b-col>
	</div>
</template>

<script>
import {
	BCol,
	BFormGroup,
	BFormInput,
	BButton,
	BFormCheckbox,
	BFormRadio,
} from 'bootstrap-vue';
import { mapActions, mapState } from 'vuex';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Swal from 'sweetalert2';

export default {
	components: {
		BFormCheckbox,
		BButton,
		BCol,
		BFormGroup,
		BFormInput,
		BFormRadio,
	},
	props: {
		refreshTable: {
			type: Function,
			default: () => {},
		},
	},
	data() {
		return {
			selectedCategory: {
				id: null,
				name: '',
			},
			productAttribute: {
				nameEng: '',
				nameGeo: '',
				isConfigurable: false,
			},
			productAttributes: [],
		};
	},
	computed: {
		...mapState('parametersModule', ['subCategories']),
		isBtnDisabled() {
			if (
				this.productAttribute.nameEng === '' ||
				this.productAttribute.nameGeo === ''
			) {
				return true;
			}
			return false;
		},
	},
	methods: {
		...mapActions('parametersModule', ['createProductAttributes']),
		resetForm() {
			this.productAttribute = {
				nameEng: null,
				nameGeo: null,
				isConfigurable: false,
			};
			this.productAttributes = [];
		},
		onClickGroupItem(id, name) {
			this.selectedCategory.id = id;
			this.selectedCategory.name = name;
		},
		addAttribute() {
			this.productAttributes.push({
				nameEng: this.productAttribute.nameEng,
				nameGeo: this.productAttribute.nameGeo,
				isConfigurable: this.productAttribute.isConfigurable,
			});
			this.productAttribute = {};
		},
		save() {
			this.addAttribute();
			if (this.productAttributes.length) {
				this.createProductAttributes({
					id: this.selectedCategory.id,
					productAttributes: this.productAttributes,
				}).then(() => {
					this.refreshTable();
					this.resetForm();
					this.$toast({
						component: ToastificationContent,
						props: {
							title: `Attributes added`,
							icon: 'BellIcon',
							variant: 'success',
						},
					});
				});
			} else {
				Swal.fire({
					title: 'დაფიქსირდა შეცდომა!',
					text: 'შესანახად შეიყვანეთ ატრიბუტი',
					icon: 'error',
					confirmButtonText: 'Try again',
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.title {
	color: #636363;
	font-size: 16px;
}
.selected-title {
	color: #636363;
	font-size: 13px;
}
.padding-zero {
	padding: 0;
}
.save-wrapper {
	display: flex;
	flex-direction: column;
}
</style>
